import ApiService from "@/core/services/api.service.js";

const attributeService = {
    getAttributes() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/attributes")
            .then(function(response) {
              if (response.data) {
                resolve(response.data[0]);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getAttributeById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/attributes/detail/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createAttribute(data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/attributes/create",data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateAttribute(data) {
        return  new Promise((resolve, reject) => {
          ApiService.put("api/admin/attributes/update/"+data.id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteAttribute(id) {
        return  new Promise((resolve, reject) => {
          ApiService.delete("api/admin/attributes/delete/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },

    //attribute groups
    getAttributeGroupList() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/attributeGroups")
            .then(function(response) {
              if (response.data) {
                resolve(response.data[0]);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getAttributeGroupById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/attributeGroups/detail/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createAttributeGroup(data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/attributeGroup/create",data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateAttributeGroup(data) {
        return  new Promise((resolve, reject) => {
          ApiService.put("api/admin/attributeGroup/update/"+data.id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteAttributeGroup(id) {
        return  new Promise((resolve, reject) => {
          ApiService.delete("api/admin/attributeGroups/delete/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    }

}

export default attributeService;